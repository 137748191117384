<template lang="pug">
  tr.matchings-item(
    @click="$emit('click')"
    :class="{ active }"
  )
    td.item-name
      AppToggle(
        :value="settingItem.enabled.$model"
        @change="$emit('toggle-enabled')"
      )
    td.item-name-dropdown
      span
        | {{ settingItem.name.$model }}
    td.text-right
      FaIcon(:icon="arrowIcon")
</template>

<script>
  export default {
    props: {
      settingItem: Object,
      active: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppToggle: () => import("@/components/elements/AppToggle")
    },

    computed: {
      arrowIcon() {
        return this.active ? "chevron-up" : "chevron-down"
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  tr
    cursor: pointer

    &:hover,
    &.active
      background: $default-purple-light

  .matchings-item
    td
      +matchings-item-td
</style>
